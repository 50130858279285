import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/cached-api';
import { urlBaseV2 } from 'src/app/services/constants';
import { Model } from 'src/app/shared/models/model';

@Component({
    selector: 'app-apply-model-dialog',
    templateUrl: './apply-model-dialog.component.html',
})
export class ApplyModelDialog implements OnInit {
    allModels: Model[] = [];
    filterValue: string;
    filteredModels: Model[] = [];
    selectedModel: Model;
    productImagePaths: Record<number, string> = {};

    constructor(
        private api: ApiService,
        public dialogRef: MatDialogRef<ApplyModelDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { models: Model[] },
    ) {}

    async ngOnInit() {
        await ensureLoaded([this.api.models]);
        this.allModels = this.filteredModels =
            this.data?.models ?? this.api.models.current();
        for (const model of this.allModels) {
            this.productImagePaths[model.id] =
                urlBaseV2 + '/models/' + model.id + '/thumbnail';
        }
    }

    filterModels() {
        this.filteredModels = this.filterValue
            ? this.allModels.filter((model) =>
                  model.name
                      .toLowerCase()
                      .includes(this.filterValue.toLowerCase()),
              )
            : this.allModels;
    }
}
